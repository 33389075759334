import { useContext, useEffect } from "react";

import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import Spinner from "../components/Spinner";
import TopBar from "../components/TopBar";
import AuthContext from "../store/AuthContext";
import Cashier from "./Cashier";
import Admin from "./Admin";
import { passiveSupport } from "passive-events-support/src/utils";
import { useSelector } from "react-redux";
import AdminBar from "../components/AdminBar";
import { Route } from "react-router-dom";
import { SignedIn, SignedOut, useAuth } from "@clerk/clerk-react";
import useClerkInfo from "../hooks/useClerkInfo";
import SignInPage from "../components/SignInPage";

passiveSupport({
  events: ["touchstart", "touchmove"],
});

const rebuildURL = (selectedEmail, selectedDeal) => {
  const urlData = {};
  if (selectedDeal) {
    urlData.deal = selectedDeal;
  }
  if (selectedEmail) {
    urlData.email = selectedEmail;
  }
  const params = Object.keys(urlData)
    .map((key) => `${key}=${urlData[key]}`)
    .join("&");

  switch (window.location.pathname) {
    case "/":
    case "/cashier": {
      window.history.pushState(
        "cashier",
        "",
        `${window.location.pathname}${params ? "?" + params : ""}`
      );
      break;
    }
  }
};

const LoggedButNoAccess = () => {
  const clerkAuth = useAuth();

  const { signOut } = clerkAuth;

  return (
    <div>
      You are logged in, but do not have access to this app.
      <div className=" cursor-pointer" onClick={signOut}>
        Log out
      </div>
    </div>
  );
};

const App = () => {
  const { hasRole } = useClerkInfo();
  const isCashierAdmin = hasRole("charging", "admin");

  const { selectedEmail, selectedDeal } = useSelector((state) => state.cashier);

  const { user = {} } = useSelector((state) => state.app);

  const authctx = useContext(AuthContext);
  const { overlay } = authctx;

  // build routes
  const signedInRoutes = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route
          path="*"
          element={
            <>
              <TopBar />
              <Cashier />
            </>
          }
        />
        {isCashierAdmin ? (
          <Route
            path="/admin"
            element={
              <>
                <AdminBar />
                <Admin />
              </>
            }
          />
        ) : null}
      </>
    )
  );

  // watch state and update url
  useEffect(() => {
    rebuildURL(selectedEmail, selectedDeal);
  }, [selectedEmail, selectedDeal]);

  console.log("app:user", user);
  return (
    <>
      <div id="modaldiv"></div>
      {!!overlay && <Spinner text={overlay} />}
      <SignedIn>
        {user?.access ? (
          <RouterProvider router={signedInRoutes} />
        ) : (
          <LoggedButNoAccess />
        )}
      </SignedIn>

      <SignedOut>
        <SignInPage />
      </SignedOut>
    </>
  );
};

export default App;
