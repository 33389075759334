import { useEffect, useRef, useState } from "react";

import * as Yup from "yup";
import { MdSearch } from "react-icons/md";
import { twMerge } from "tailwind-merge";
import useDebouncedInput from "../hooks/useDebouncedInput";
import {
  useCreateHubspotContactMutation,
  useLazySearchHubspotContactsQuery,
  useSearchHubspotContactsQuery,
} from "../store/api";
import { useDispatch, useSelector } from "react-redux";

import StyledFormikField from "./StyledFormikField";
import { selectEmail } from "../store/cashier";
import { Form, Formik } from "formik";
import { createPortal } from "react-dom";
import Backdrop from "./Backdrop";
import { HUBSPOTRETRIES } from "../store/constants";

export const ChosenEmail = (props) => (
  <div className="flex flex-col">
    <div className="p-2 my-1 mr-auto border border-solid rounded shadow-md bg-gray-50">
      <i className="">{props.chosenEmail}</i>
      <span className="ml-2 mr-1 cursor-pointer" onClick={props.onClear}>
        X
      </span>
    </div>
    {props.children}
  </div>
);

// eslint-disable-next-line no-undef
const devMode = process.env.REACT_APP_devMode;

const CreateContactDialog = (props) => {
  const { email, close } = props;
  const formRef = useRef();

  const [createContactCall] = useCreateHubspotContactMutation();

  const lazyContactQuery = useLazySearchHubspotContactsQuery();
  const checkContactExists = async (email, counter = 0) => {
    // console.log("check if contact exists:", email, counter);

    const [searchContact] = lazyContactQuery;
    const results = await searchContact({ email }, false).unwrap();

    if (results.length) {
      // console.log("got one! ending this loop");
      return;
    }

    if (counter < HUBSPOTRETRIES) {
      setTimeout(() => {
        checkContactExists(email, counter + 1); // recursive
      }, 1000);
    } else {
      setProcessingText({
        text: "Never got an answer from hubspot, please CLICK HERE to refresh",
        url: `${location.href}?email=${email}`,
      });
    }
  };

  const createContact = async ({ email, fullName }) => {
    const created = await createContactCall({ email, fullName });
    console.log("contact created, wait for him to show up", created);

    checkContactExists(email);
  };

  const handleKey = (event) => {
    // console.log("User keypress", event.key);

    switch (event?.key) {
      case "Enter":
        formRef.current.onSubmit();
        break;
      case "Escape":
        close();
        break;
      default:
        break;
    }
  };

  const [processingText, setProcessingText] = useState({ text: "", url: "" });

  useEffect(() => {
    document.addEventListener("keydown", handleKey);

    return () => {
      document.removeEventListener("keydown", handleKey);
    };
  });

  const handleSubmit = async (props) => {
    setProcessingText({
      text: "Waiting for customer to show up in Hubspot…",
      url: null,
    });

    createContact({
      email,
      fullName: props.name,
    });
  };

  return createPortal(
    <>
      <Backdrop />
      <div className="fixed flex flex-col items-center justify-center w-full h-full z-[2] ">
        <div className="w-9/12 p-2 bg-white rounded shadow-lg lg:w-6/12 xl:w-4/12">
          <Formik
            innerRef={formRef}
            initialValues={{
              name: "",
            }}
            validationSchema={Yup.object({
              name: Yup.string().required().min(3),
            })}
            onSubmit={handleSubmit}
          >
            {(form) => {
              const { errors } = form;
              const anyError = !!Object.keys(errors).length;
              return (
                <Form>
                  <div className="p-2">
                    <div className="my-1 mt-4 text-blue-400 ">
                      Create Hubspot Contact
                    </div>
                    <div> Email: {email}</div>
                    <div className={twMerge("flex flex-col")}>
                      <label
                        htmlFor="name"
                        className="my-1 mt-4 text-blue-400 "
                      >
                        Name
                      </label>
                      <StyledFormikField
                        id="name"
                        name="name"
                      ></StyledFormikField>
                    </div>
                    {processingText?.text && (
                      <span
                        className={twMerge(
                          "text-green-700 ",
                          processingText?.url && "cursor-pointer text-red-500"
                        )}
                      >
                        {processingText?.url ? (
                          <a href={processingText.url}>{processingText.text}</a>
                        ) : (
                          processingText.text
                        )}
                      </span>
                    )}
                    <div className="flex gap-x-4">
                      <button
                        disabled={processingText?.text}
                        className={twMerge(
                          "w-full p-2 my-2 font-semibold border rounded"
                        )}
                        onClick={close}
                      >
                        Cancel
                      </button>
                      <button
                        disabled={anyError || processingText?.text}
                        className={twMerge(
                          "w-full p-2 my-2 font-semibold text-white bg-blue-400",
                          (anyError || processingText?.text) && "bg-gray-400"
                        )}
                        type="submit"
                      >
                        Create
                      </button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>,
    document.getElementById("modaldiv")
  );
};

const CreateContact = (props) => {
  const { email, onCreate, looking } = props;
  const [dialog, setDialog] = useState(false);
  const createAccess = true;

  const { user } = useSelector((state) => state.app);
  const { userGroupId } = user; // TODO rewrite userGroupId to EDIT privilege throughout the app

  // if no access, this is the end
  if (!createAccess) {
    return `-No matches for "${email}"-`; // in hubspot
  }

  // Suggest creation
  return (
    <div>
      {!looking && (
        <div>
          No matches for {email}
          {!!(userGroupId === 0) && (
            <button
              className={twMerge(
                "w-full p-2 my-2 font-semibold text-white bg-blue-400"
              )}
              onClick={() => setDialog(true)}
              type="button"
            >
              Create contact
            </button>
          )}
        </div>
      )}
      {dialog && (
        <CreateContactDialog
          email={email}
          close={() => setDialog(() => false)}
          onCreate={onCreate}
        />
      )}
    </div>
  );
};

const EmailLookup = (props) => {
  const {
    onClear,
    currentEmail, // current form value if we're hidden and shown again
  } = props;
  // get deals with hook etc

  const dispatch = useDispatch();

  const [email, debounceEmail] = useDebouncedInput({
    defaultText: currentEmail,
  });
  const [searching, setSearching] = useState(false);

  const handleChange = (email) => {
    if (email.trim().length < 3) {
      debounceEmail(email, true);
      setUniqueEmails(() => []);
      return;
    }
    setSearching(() => true);
    debounceEmail(email);
  };

  const { data: hsContacts, isFetching: isFetchingContacts } =
    useSearchHubspotContactsQuery({ email }, { skip: email.trim().length < 3 });

  const setContact = (email) => {
    console.log("set contact email", email);
    dispatch(selectEmail(email));
  };

  const [uniqueEmails, setUniqueEmails] = useState([]);

  useEffect(() => {
    setSearching(() => false);
    if (!hsContacts?.length) {
      return setUniqueEmails(() => []);
    }

    setUniqueEmails(() => hsContacts.map((entry) => entry.properties.email));

    // if we want to autoselect
    if (hsContacts?.length === 1) {
      onClear(); // must clear to avoid autoselecting by default next time
      const { email } = hsContacts[0].properties;
      setContact(email);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hsContacts]);

  return (
    <>
      <label htmlFor="email" className="mt-4 text-blue-400">
        by email{devMode && " (try corbinhibbs)"}
      </label>

      <div>
        <StyledFormikField
          type="email"
          className={twMerge("p-2 w-full")}
          name="email"
          tellChange={handleChange}
          iconclassname={searching ? " animate-ping" : ""}
          icon={searching ? MdSearch : null}
        />

        <ul>
          {!uniqueEmails.length && email.trim() && (
            <i>
              {email.trim().length < 3
                ? "Enter at least 3 characters"
                : isFetchingContacts && "Loading emails…"}
              {/* don't remount createcontact on re-fetch */}

              <CreateContact
                looking={isFetchingContacts || searching}
                email={email}
                onCreate={setContact}
              />
            </i>
          )}

          {uniqueEmails.map((eml, index) => (
            <li
              onClick={() => setContact(eml)}
              className="mt-2 cursor-pointer"
              key={eml}
            >
              <i>{index + 1}</i>
              {": " + eml}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default EmailLookup;
