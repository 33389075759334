import { useEffect, useRef, useState } from "react";
import Box from "./Box";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import StyledFormikField from "./StyledFormikField";
import { MdSearch } from "react-icons/md";

import SubscriptionLookup from "./SubscriptionLookup";
import EmailLookup, { ChosenEmail } from "./EmailLookup";
import {
  useCreateDealMutation,
  useGetDealQuery,
  useGetDealsForEmailQuery,
  useGetProductsQuery,
} from "../store/api";
import useDebouncedInput from "../hooks/useDebouncedInput";
import { useDispatch, useSelector } from "react-redux";
import { selectDeal, selectEmail } from "../store/cashier";
import { DealsList } from "./DealsList";
import { twMerge } from "tailwind-merge";
import ProductDropdown from "./ProductDropdown";
import { LBL_PRODUCT } from "../store/constants";
import { createPortal } from "react-dom";
import Backdrop from "./Backdrop";

// eslint-disable-next-line no-undef
const devMode = process.env.REACT_APP_devMode;

const CreateDealDialog = (props) => {
  const { email, close, onCreate } = props;
  const { data } = useGetProductsQuery();
  const { stripePrices: products = [] } = data ?? {};

  const [createDeal] = useCreateDealMutation();

  const { contact } = useSelector((state) => state.cashier);

  const formRef = useRef();

  const handleKey = (event) => {
    // console.log("User keypress", event.key);

    switch (event?.key) {
      case "Enter":
        formRef.current.onSubmit();
        break;
      case "Escape":
        close();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKey);
    return () => {
      document.removeEventListener("keydown", handleKey);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (values) => {
    const { product } = values;
    const found = products.find((prod) => +prod.id === +product);

    console.log("create deal ", values, contact);
    if (!found || !contact?.id) {
      console.log("missing something!", values, found, contact);
    }
    const { data: deal } = await createDeal({
      contactid: contact?.id,
      // contactemail: email,
      dealname: found?.name,
    });
    console.log("created deal ", deal);
    onCreate(deal.id); // select that email
    close();
  };

  return createPortal(
    <>
      <Backdrop />
      <div className="fixed flex flex-col items-center justify-center w-full h-full z-[2] ">
        <div className="w-9/12 p-2 bg-white rounded shadow-lg lg:w-6/12 xl:w-4/12">
          <Formik
            innerRef={formRef}
            initialValues={{ product: "" }}
            onSubmit={handleSubmit}
          >
            {(form) => {
              const { errors, values } = form;

              const handleProductChange = (prodId) => {
                console.log("chgprod", prodId);
                form.setFieldValue("product", prodId);
              };

              const anyError = !!Object.keys(errors).length || !values.product;
              return (
                <Form>
                  <div className="p-2">
                    <div className="my-1 mt-4 text-blue-400 ">
                      Create Deal in Hubspot
                    </div>
                    <div> Email: {email}</div>
                    <ProductDropdown
                      grouplabel="Product for deal"
                      label={LBL_PRODUCT}
                      name="product"
                      selected={values.product}
                      onChange={handleProductChange}
                      products={products}
                      labelWithPrice
                    />

                    <div className="flex gap-x-4">
                      <button
                        className={twMerge(
                          "w-full p-2 my-2 font-semibold border rounded"
                        )}
                        onClick={close}
                      >
                        Cancel
                      </button>
                      <button
                        disabled={anyError}
                        className={twMerge(
                          "w-full p-2 my-2 font-semibold text-white bg-blue-400",
                          anyError && "bg-gray-400"
                        )}
                        type="submit"
                      >
                        Create
                      </button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>,
    document.getElementById("modaldiv")
  );
};

const CreateDeal = (props) => {
  const {
    email,
    onCreate,
    // deals
  } = props;

  const [dialog, setDialog] = useState(false);
  // Suggest creation
  return (
    <div>
      <button
        className={twMerge(
          "w-full p-2 my-2 font-semibold text-white bg-blue-400"
        )}
        onClick={() => setDialog(true)}
        type="button"
      >
        Create deal
      </button>
      {dialog && (
        <CreateDealDialog
          email={email}
          close={() => setDialog(() => false)}
          onCreate={onCreate}
        />
      )}
    </div>
  );
};

const SelectDeal = () => {
  const {
    // mode,
    selectedEmail,
  } = useSelector((state) => state.cashier);
  const dispatch = useDispatch();

  const clearMail = () => {
    dispatch(selectEmail(null));
  };

  const formRef = useRef();

  const [debouncedDealId, debounceDeal] = useDebouncedInput();

  const { data: deals = [], isFetching: isFetchingDeals } =
    useGetDealsForEmailQuery(
      {
        email: selectedEmail,
      },
      {
        skip: !selectedEmail,
      }
    );

  // redux query watching dealId
  const dealQuery = useGetDealQuery(
    { id: debouncedDealId }, // will re-fetch when lookupDealId changes. kind of dependency
    {
      skip: !debouncedDealId,
    }
  );

  const {
    data: dealFetched,
    error: dealFetchError,
    // isLoading: dealLoading, // FIRST load
    isFetching: isFetchingDeal, // redux is currently [re]fetching
  } = dealQuery;

  const pickDeal = async (dealId) => {
    dispatch(selectDeal(dealId));
  };

  // WATCH typed-deals lookuup
  useEffect(() => {
    if (dealFetched?.id) {
      dispatch(selectDeal(dealFetched.id));
    }
  }, [dealFetched, dispatch]);

  return (
    <>
      <Formik
        innerRef={formRef}
        initialValues={{
          deal: "",
          sub: "",
          email: "",
        }}
        validationSchema={Yup.object({
          deal: Yup.number()
            .typeError("Must be number")
            .integer("Must be integer")
            .positive("Must be positive"),
          email: Yup.string().min(3, "Enter at least 3 characters to search"),
        })}
      >
        {(form) => {
          // console.log("got form", form);// here we have all the form methods
          const { errors, values } = form;

          // Form wide logic here

          const onDealChange = (newDealId) => {
            if (!newDealId.trim()) {
              return;
            }
            debounceDeal(newDealId);
          };

          const clearField = (field) => {
            form.setFieldValue(field, "");
          };

          return (
            <Form className="px-4">
              {!selectedEmail && (
                <Box>
                  <div className={"flex flex-col "}>
                    <label htmlFor="deal" className="text-blue-400">
                      Manual entry: Deal ID
                    </label>
                    <StyledFormikField
                      tellChange={onDealChange}
                      type="text"
                      className="p-2"
                      name="deal"
                      iconclassname={isFetchingDeal ? " animate-ping" : ""}
                      icon={isFetchingDeal ? MdSearch : null}
                    />
                    {(errors.deal || dealFetchError?.data?.error) && (
                      <div className="text-center text-red-500">
                        {errors.deal || dealFetchError?.data?.error}
                      </div>
                    )}
                    {!dealFetched && (
                      <div className="text-sm text-gray-500 ">
                        How to find my deal ID -&gt;{" "}
                        <a
                          className="underline"
                          href="https://bit.ly/3xUOdJp"
                          target="_blank"
                          rel="noreferrer"
                        >
                          https://bit.ly/3xUOdJp
                        </a>
                        {devMode && (
                          <p>
                            {"Test stuff "}
                            <br />
                            {/* <a
                              className="underline"
                              href="http://localhost:3000/cashier?deal=5299878106"
                            >
                              OK deal
                            </a> */}
                            <br />

                            {"PROD 5299878106"}
                            <br />
                            {
                              "SAND OK 5487318988 | Missing contact 5495057343 | Missing lineitem 5520365758 | Missing stripecustomer <create a new customer and a deal, then copy the deal # here>"
                            }
                            <br />
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                </Box>
              )}

              {/* Deal lookup */}
              <Box>
                <label htmlFor="email" className="mt-4 text-blue-400">
                  Lookup Deal…
                </label>
                <div className="pl-4">
                  {selectedEmail ? (
                    <>
                      <ChosenEmail
                        chosenEmail={selectedEmail}
                        onClear={clearMail}
                      ></ChosenEmail>
                      {!!isFetchingDeals && <div>Loading…</div>}
                      {!isFetchingDeals && (
                        <>
                          {deals?.length ? (
                            <>
                              <DealsList
                                isFetching={isFetchingDeals}
                                deals={deals}
                                pickDeal={pickDeal}
                              />
                            </>
                          ) : (
                            <>
                              <div>
                                Oh no! No deals found for <i>{selectedEmail}</i>
                                !
                              </div>
                              <CreateDeal
                                deals={deals}
                                email={selectedEmail}
                                onCreate={pickDeal}
                              />
                            </>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <EmailLookup
                        currentEmail={values.email}
                        onClear={() => clearField("email")}
                      />
                      <SubscriptionLookup
                        onClear={() => clearField("sub")}
                        pickDeal={pickDeal}
                      />
                    </>
                  )}
                </div>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default SelectDeal;
